let STATS = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient;
    let ServiceBaseAddress = serviceAddr;
    /**
     * 医院后台统计控制器---医院浏览排行榜
     * @param startTime
     * @param endTime
     * @param pageIndex
     * @param success
     * @param error
     */
    this.hospitalList = function (startTime, endTime, pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/HospitalStatistics/HospitalBrowseTop';

        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    this.hospitalDetail = function (hospitalId, startTime, endTime, pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/HospitalStatistics/HospitalBrowseTopDetail';
        if (hospitalId) {
            url += `/${hospitalId}`;
        }
        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    /**
     * 医院后台统计控制器---科室浏览排行榜
     * @param startTime
     * @param endTime
     * @param pageIndex
     * @param success
     * @param error
     */
    this.departmentList = function (startTime, endTime, pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/HospitalStatistics/DepartmentBrowseTop';

        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.departmentDetail = function (hospitalId, departmentId, startTime, endTime, pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/HospitalStatistics/DepartmentBrowseTop';
        if (hospitalId) {
            url += `/${hospitalId}`;
        }
        if (departmentId) {
            url += `/${departmentId}`;
        }
        else {
            url += '/0';
        }
        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 医院后台统计控制器---疾病时间统计--中医
     * @param startTime
     * @param endTime
     * @param pageIndex
     * @param success
     * @param error
     * @constructor
     */
    this.ZDiseaseList = function (startTime, endTime, pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/HospitalStatistics/ZDiseaseBrowseTop';

        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    /**
     * 医院后台统计控制器---疾病时间统计--西医
     * @param startTime
     * @param endTime
     * @param pageIndex
     * @param success
     * @param error
     * @constructor
     */
    this.XDiseaseList = function (startTime, endTime, pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/HospitalStatistics/XDiseaseBrowseTop';

        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    /**
     * 医院后台统计控制器---疾病时间统计--西药
     * @param startTime
     * @param endTime
     * @param pageIndex
     * @param success
     * @param error
     * @constructor
     */
    this.XDrugBrowseList = function (startTime, endTime, pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/HospitalStatistics/XDrugBrowseTop';

        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    /**
     * 医院后台统计控制器---疾病时间统计--中成药
     * @param startTime
     * @param endTime
     * @param pageIndex
     * @param success
     * @param error
     * @constructor
     */
    this.ZDrugBrowseList = function (startTime, endTime, pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/HospitalStatistics/ZDrugBrowseTop';

        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    /**
     * 医院后台统计控制器---中药时间统计
     * @param startTime
     * @param endTime
     * @param pageIndex
     * @param success
     * @param error
     * @constructor
     */
    this.MaterialBrowseList = function (startTime, endTime, pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/HospitalStatistics/MaterialBrowseTop';

        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 医院后台统计控制器---中药方剂时间统计
     * @param startTime
     * @param endTime
     * @param pageIndex
     * @param success
     * @param error
     * @constructor
     */
    this.HerbalBrowseList = function (startTime, endTime, pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/HospitalStatistics/HerbalBrowseTop';

        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 医院后台统计控制器---食疗药膳时间统计
     * @param startTime
     * @param endTime
     * @param pageIndex
     * @param success
     * @param error
     * @constructor
     */
    this.FoodBrowseList = function (startTime, endTime, pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/HospitalStatistics/FoodBrowseTop';

        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    this.EntityBrowseTopDetail = function (type, entityKey, startTime, endTime, pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/HospitalStatistics/EntityBrowseTopDetail';
        if (type) {
            url += `/${type}`;
        }
        if (entityKey) {
            url += `/${entityKey}`;
        }
        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    this.CompatibilityBrowseTopDetail = function (learnName, startTime, endTime, pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/HospitalStatistics/CompatibilityBrowseTopDetail';
        if (learnName) {
            url += `/${learnName}`;
        }
        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    /**
     * 医院后台统计控制器---经络时间统计
     * @param startTime
     * @param endTime
     * @param pageIndex
     * @param success
     * @param error
     * @constructor
     */
    this.MeridianBrowseList = function (startTime, endTime, pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/HospitalStatistics/MeridianBrowseTop';

        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    /**
     * 医院后台统计控制器---穴位时间统计
     * @param startTime
     * @param endTime
     * @param pageIndex
     * @param success
     * @param error
     * @constructor
     */
    this.AcupointBrowseList = function (startTime, endTime, pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/HospitalStatistics/AcupointBrowseTop';

        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    /**
     * 医院后台统计控制器---推拿手法时间统计
     * @param startTime
     * @param endTime
     * @param pageIndex
     * @param success
     * @param error
     * @constructor
     */
    this.ManipulationBrowseList = function (startTime, endTime, pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/HospitalStatistics/ManipulationBrowseTop';

        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    /**
     * 医院后台统计控制器---配伍时间统计
     * @param startTime
     * @param endTime
     * @param pageIndex
     * @param success
     * @param error
     * @constructor
     */
    this.CompatibilityBrowseList = function (startTime, endTime, pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/HospitalStatistics/CompatibilityBrowseTop';

        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
         * 会员分红统计
         * @param startTime
         * @param endTime
         * @param pageIndex
         * @param keyWord
         * @param success
         * @param error
         * @constructor
         */
     this.Share = function (startTime, endTime, pageIndex, keyWord,pageSize, success, error) {
        var url = ServiceBaseAddress + '/api/Statistics/Divvy/Org'

        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        if (pageSize) {
            url += `/${pageSize}`;
        }
        TokenClient.Get(
            url,
            true,
            null,
            null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    /**
         * 会员分红详情
         * @param startTime
         * @param endTime
         * @param pageIndex
         * @param keyWord
         * @param success
         * @param error
         * @constructor
         */
    this.ShareDetail = function (userId, startTime, endTime, pageIndex, keyWord,pageSize, success, error) {
        var url = ServiceBaseAddress + '/api/Statistics/Divvy/OrgDetail/' + userId

        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        if (pageSize) {
            url += `/${pageSize}`;
        }
        TokenClient.Get(
            url,
            true,
            null,
            null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    /**
         * 会员消费统计
         * @param startTime
         * @param endTime
         * @param pageIndex
         * @param keyWord
         * @param success
         * @param error
         * @constructor
         */
     this.Consumption = function (startTime, endTime, pageIndex, keyWord, success, error) {
        var url = ServiceBaseAddress + '/api/Statistics/Consumption/Org'

        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        TokenClient.Get(
            url,
            true,
            null,
            null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    /**
         * 会员消费明细
         * @param startTime
         * @param endTime
         * @param pageIndex
         * @param keyWord
         * @param success
         * @param error
         * @constructor
         */
     this.ConsumptionDetail = function (userId,startTime, endTime, pageIndex, keyWord,pageSize, success, error) {
         var url = ServiceBaseAddress + '/api/Statistics/Consumption/OrgDetail/' + userId
        //  if (type) {
        //     url += `/${type}`;
        // }
        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        if (pageSize) {
            url += `/${pageSize}`;
        }
        TokenClient.Get(
            url,
            true,
            null,
            null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    this.getOrgStatistics = function(dataType,userType, startTime,endTime, keyWord, pageIndex,success, error) {
        var url = ServiceBaseAddress + '/api/Agent/OrgStatistics/'
        if (dataType) {
            url += `/${dataType}`;
        }
        if (userType) {
            url += `/${userType}`;
        }
        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
 
    
        TokenClient.Get(
            url,
            true,
            null,
            null,
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
}

export { STATS }
